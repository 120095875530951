import Home from "screens/Home";
import Login from "screens/Login";
import ErrorView from "screens/404";
import Layout from "layouts/Layout";
import Lenders from "screens/Lenders";
import Contactus from "screens/ContactUs";
import FinalOffer from "screens/FinalOffer";
import HomeMonitor from "screens/HomeMonitor";
import InititalOffer from "screens/InitialOffer";
import ForgotPassword from "screens/ForgotPassword";
import BorrowerTracker from "screens/BorrowerTracker";
import ValidateAccount from "screens/ValidateAccount";
import LayoutWithContexts from "layouts/LayoutWithContexts";
import TypeOfCreditLine from "screens/TypeOfCreditLine";
import CoborrowerTracker from "screens/CoborrowerTracker";
import ResendValidateCode from "screens/ResendValidateCode";
import BorrowerRegisterForm from "screens/BorrowerRegisterForm";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import CoborrowerRegisterForm from "screens/CoborrowerRegisterForm";
import ConfirmNewPassword from "screens/ConfirmNewPassword";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import CLoader from "components/CLoader";
import BorrowerInviteRegisterForm from "screens/BorrowerInviteRegisterForm";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import Licenses from "screens/Licenses";
import { LStoken } from "CONST";
import ScreenAfterMLORegister from "screens/ScreenAfterMLORegister";

const AppRoutes = () => {
  const { loan, user, loadingLoan } = useUserCustomerContext();
  const { privateLabel } = usePrivateLabel();
  const hasToken = localStorage.getItem(LStoken);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasInviteCode = queryParams.has("code");

  const isBorrower = user && user.id === loan?.borrowerId;
  const isCoborrower = user && user.id === loan?.coborrowerId;

  const typeOfCreditLine =
    isBorrower &&
    (!loan?.loanType ||
      loan?.borrowerFlags?.userHasLoanInOtherPrivateLabel ||
      (loan.loanType && loan.borrowerFlags?.completedByBorrower));

  const isFunded =
    (isBorrower || isCoborrower) &&
    loan?.borrowerSteps?.fundingVerification.status === "success";

  const finalOffer =
    (isBorrower &&
      !loan?.borrowerFlags?.finalOfferAccepted &&
      loan?.borrowerSteps?.incomeVerification?.status === "success" &&
      loan?.borrowerSteps?.creditVerification?.status === "success" &&
      loan?.borrowerSteps?.titleVerification?.status === "success" &&
      loan?.borrowerSteps?.idVerification?.status === "success" &&
      (loan.borrowerSteps.homeValuationVerification.status === "success" ||
        (loan.borrowerSteps.homeValuationVerification.status === "analyzing" &&
          loan.borrowerSteps?.homeValuationVerification?.reportType === "BPO" &&
          loan.borrowerSteps?.homeValuationVerification?.orderId))) ||
    loan?.borrowerFlags?.reAcceptedFinalOffer === false;

  const initialOffer =
    isBorrower &&
    !loan?.borrowerFlags?.initialOfferAccepted &&
    (!loan?.loanType ||
      loan?.borrowerFlags?.completedByBorrower ||
      loan?.borrowerFlags?.userHasLoanInOtherPrivateLabel);

  const borrowerTracker =
    isBorrower && loan?.borrowerSteps !== undefined && loan?.loanType;

  const coborrowerTracker = isCoborrower && loan?.coborrowerSteps !== undefined;

  return (
    <Routes>
      <Route element={<LayoutWithContexts />}>
        {privateLabel?.isNFTYDoor && (
          <Route path="/lenders" element={<Lenders />} />
        )}
        {loadingLoan && (
          <>
            <Route path="/final-offer" element={<CLoader loading={true} />} />
            <Route path="/home-monitor" element={<CLoader loading={true} />} />
            <Route path="/initial-offer" element={<CLoader loading={true} />} />
            <Route
              path="/coborrower-tracker"
              element={<CLoader loading={true} />}
            />
            <Route
              path="/type-of-credit-line"
              element={<CLoader loading={true} />}
            />
            <Route
              path="/borrower-tracker"
              element={<CLoader loading={true} />}
            />
          </>
        )}
        {loan && !loadingLoan && (
          <>
            {finalOffer && (
              <Route path="/final-offer" element={<FinalOffer />} />
            )}
            {isFunded && (
              <Route path="/home-monitor" element={<HomeMonitor />} />
            )}
            {initialOffer && (
              <Route path="/initial-offer" element={<InititalOffer />} />
            )}
            {coborrowerTracker && (
              <Route
                path="/coborrower-tracker"
                element={<CoborrowerTracker />}
              />
            )}
            {typeOfCreditLine && (
              <Route
                path="/type-of-credit-line"
                element={<TypeOfCreditLine />}
              />
            )}
            {(borrowerTracker || coborrowerTracker) && (
              <Route path="/borrower-tracker" element={<BorrowerTracker />} />
            )}
          </>
        )}
        {privateLabel?.hasOrganicFlow || hasInviteCode ? (
          <>
            <Route path="/signup" element={<BorrowerRegisterForm />} />
            <Route
              path="/:sourceId/signup"
              element={<BorrowerRegisterForm />}
            />
            <Route
              path="/coborrower-signup"
              element={<CoborrowerRegisterForm />}
            />
          </>
        ) : null}
        <Route
          path="/:wholesaleEntity/:brokerName/:loName/signup"
          element={<BorrowerInviteRegisterForm />}
        />
        <Route path={`/submitted`} element={<ScreenAfterMLORegister />} />
      </Route>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<ErrorView />} />
        <Route path="/login" element={<Login />} />
        {privateLabel?.hasOrganicFlow && (
          <>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/confirm-new-password"
              element={<ConfirmNewPassword />}
            />
            <Route
              path="/resend-validate-code"
              element={<ResendValidateCode />}
            />
            <Route
              path="/validate-account/:email"
              element={<ValidateAccount />}
            />
          </>
        )}
        <Route path="/licenses" element={<Licenses />} />
        <Route path="/contactus" element={<Contactus />} />
        {!hasToken && (
          <>
            <Route
              path="/borrower-tracker"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/coborrower-tracker"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/final-offer"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/home-monitor"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/initial-offer"
              element={<Navigate to="/login" replace />}
            />
            <Route
              path="/type-of-credit-line"
              element={<Navigate to="/login" replace />}
            />
          </>
        )}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
