import { useTheme } from "@mui/material";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";
import dollarFormatter from "utils/FormatterDollar";
import { messageCardStyles } from "..";

type ProgressBarParameters = {
  step: BorrowerSteps | CoborrowerSteps | undefined;
  isBorrower: boolean;
};

type VerifiedSliderProps = {
  value: number;
  showValue: boolean;
};
const ProgressBarSection = ({ step, isBorrower }: ProgressBarParameters) => {
  const theme = useTheme();

  const VerifiedSlider = styled(Slider)<VerifiedSliderProps>(
    ({ value, showValue }) => ({
      color: "#34C155",
      height: 20,
      "& .MuiSlider-track": {
        backgroundColor: "green",
        position: "relative",
        "&::before": {
          content: '"VERIFIED INCOME"',
          color: "white",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "0.75rem",
          whiteSpace: "nowrap",
        },
        "&::after": {
          content: showValue
            ? `"${dollarFormatter.format(Number(value))}"`
            : '""',
          color: "black",
          position: "absolute",
          right: -40,
          top: 25,
          fontSize: "0.85rem",
          whiteSpace: "nowrap",
        },
      },
      "& .MuiSlider-rail": {
        color: "red",
      },
      "& .MuiSlider-thumb": {
        display: "none",
      },
    }),
  );

  const incomeSource = {
    statedBy: isBorrower ? "B1" : "B2",
    annualIncome: step?.incomeVerification?.annualIncome ?? 0,
    totalIncome: step?.incomeVerification?.incomeSources
      ? Object.values(step?.incomeVerification?.incomeSources).reduce(
          (sum: number, value: number) => sum + value,
          0,
        )
      : 0,
  };

  const selfEmployedData =
    step?.incomeVerification?.selfEmployed?.selfEmployedData?.filter(
      (income) => {
        return (
          income?.incomeSource &&
          income?.uploadedByBorrower &&
          !income?.debtIncome
        );
      },
    );

  const additionalIncomeData =
    step?.incomeVerification?.selfEmployed?.selfEmployedData?.filter(
      (income) => {
        return (
          !income?.incomeSource &&
          income?.uploadedByBorrower &&
          !income?.debtIncome
        );
      },
    );

  const difference = incomeSource.totalIncome - incomeSource.annualIncome;
  const totalIncomeIfExceded =
    incomeSource.annualIncome > incomeSource.totalIncome
      ? incomeSource.annualIncome
      : incomeSource.totalIncome;

  return (
    <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box key={incomeSource.statedBy} style={{ width: "100%" }}>
        <Box style={{ alignItems: "center" }}>
          {difference >= 1 && (
            <Box
              sx={{
                ...messageCardStyles,
                fontWeight: "bolder",
                backgroundColor: theme.palette.background.default,
              }}
            >
              Your application stated you earn{" "}
              {dollarFormatter.format(incomeSource.totalIncome)}. We have
              verified {dollarFormatter.format(incomeSource.annualIncome)} so
              far.
              <br />
              To hold your current loan request,{" "}
              <span
                style={{
                  ...messageCardStyles,
                  fontWeight: "bolder",
                }}
              >
                you need to verify at least {dollarFormatter.format(difference)}{" "}
                more.
              </span>
            </Box>
          )}
          {selfEmployedData?.map((income) => (
            <Box
              key={income?.id}
              sx={{
                ...messageCardStyles,
                fontWeight: "bolder",
              }}
            >
              We are reviewing the document you provided to verify{" "}
              {dollarFormatter.format(income?.annual)} from {income?.incomeType}
              .
            </Box>
          ))}
          {additionalIncomeData?.map((income) => (
            <Box
              key={income?.id}
              sx={{
                ...messageCardStyles,
                fontWeight: "bolder",
              }}
            >
              You added {dollarFormatter.format(income?.annual)} as Additional
              Income for {income?.incomeType}. We are verifying the document you
              provided.
            </Box>
          ))}

          <Box style={{ margin: 30 }}>
            <VerifiedSlider
              value={incomeSource.annualIncome}
              showValue={difference >= 1}
              aria-labelledby="verification-slider"
              disabled
              min={0}
              max={incomeSource.totalIncome}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 5,
              }}
            >
              <span>{dollarFormatter.format(0)}</span>
              <span>{dollarFormatter.format(totalIncomeIfExceded)}</span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ProgressBarSection;
