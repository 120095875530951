import {
  Box,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  CircularProgress,
} from "@mui/material";
import { FC } from "react";
import Loan from "typedef/Loan";
import dollarFormatter from "utils/FormatterDollar";
import CButton from "components/CButton";
import ChangeB2ToNPEModal from "../ChangeB2ToNPEModal";
import { UseDebtsReturnProps } from "./useDebts";
import HelpDialog from "../HelpDialog";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";
import CDialog from "components/CDialog";
import checkTenMonthsMaturity from "./checkTenMonthsMaturity";
import DebtItem from "./DebtItem";
import ChangeDebtsInformationModal from "./ChangeDebtsInformationModal";

type DebtsTableProps = {
  loan?: Loan;
  isBorrower: boolean;
  step?: BorrowerSteps | CoborrowerSteps;
  showDebts?: () => void;
  dtiItems: { dti: number | undefined; dtiHurdle: number; dtiAdjustor: number };
  useDebts: UseDebtsReturnProps;
};

const MIN_BALANCE_TRADELINE = 0;

const DebtsTable: FC<DebtsTableProps> = ({
  loan,
  isBorrower,
  step,
  showDebts,
  dtiItems,
  useDebts,
}) => {
  const {
    isProposedPaydown,
    theme,
    tradelines,
    isLoading,
    hanleSelectTradeLine,
    handleConfirmDebts,
    dti,
    openModalB2toNPE,
    needsHelp,
    openHelpDialog,
    setOpenHelpDialog,
    totalBalanceSelected,
    netFunding,
    checkedItems,
    showDTI,
    handleCloseModalB2ToNPE,
    requestedAdmin,
    errorDataMessage,
    setErrorDataMessage,
    handleRefreshData,
    checkedDebts,
    openSlider,
    setOpenSlider,
    currentId,
    handleNext,
  } = useDebts;

  let awaitB2Register = false;

  const maxdti = dtiItems?.dti;

  if (
    loan?.borrowerSteps?.incomeVerification?.isJointAccount &&
    loan?.coborrowerId?.includes("temp")
  ) {
    awaitB2Register = true;
  }

  if (!tradelines) {
    return <CircularProgress size={32} sx={{ padding: "6px" }} />;
  }

  const balance = Number(netFunding) - Number(totalBalanceSelected);
  const colorBalance =
    Number(balance) < Number(netFunding) / 4
      ? "red"
      : Number(balance) < Number(netFunding) / 2
        ? "#FFC857"
        : "green";

  const adminNeedToReviewIncomes =
    step?.incomeVerification?.selfEmployed?.selfEmployedData.some(
      (item) => item?.uploadedByBorrower && !item?.debtIncome,
    );

  return (
    <>
      <Box sx={{ width: "100%" }}>
        {showDTI && (
          <Box>
            <ChangeB2ToNPEModal
              openModalB2toNPE={openModalB2toNPE}
              handleCloseModalB2ToNPE={handleCloseModalB2ToNPE}
            />
            {!loan?.[isBorrower ? "borrowerFlags" : "coborrowerFlags"]
              ?.debtsCofirmed ? (
              <>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "18px" }}
                >
                  <Typography fontSize={20} color={"black"}>
                    {isProposedPaydown
                      ? "Our team has reviewed your Income and Debts and we need to make the following payment(s) to get your DTI within our guidelines, please review and then click “Confirm and Proceed to Next Step”."
                      : "You may be able to lower your DTI by paying off debt(s) from the list below"}
                  </Typography>
                  <Typography fontSize={20} color={"black"}>
                    Pay off your debts
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Typography fontSize={17} color={colorBalance}>
                      Available Balance: {dollarFormatter.format(balance)}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ overflowX: "auto", width: "100%" }}>
                  <TableContainer sx={{ width: "100%", minWidth: 650 }}>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableHead sx={{ borderBottom: "2px solid whitesmoke" }}>
                        <TableRow
                          sx={{
                            "& td, & th": {
                              border: 0,
                              margin: 0,
                              paddingLeft: "0px",
                              paddingBottom: "1px",
                              textWrap: "nowrap",
                            },
                          }}
                        >
                          <TableCell
                            key={"TradelineName"}
                            sx={{
                              fontSize: "18px",
                              color: theme.palette.grey["400"],
                            }}
                          >
                            Tradeline name
                          </TableCell>
                          <TableCell
                            align="inherit"
                            key={"Type"}
                            sx={{
                              fontSize: "18px",
                              color: theme.palette.grey["400"],
                            }}
                          >
                            Type
                          </TableCell>
                          <TableCell
                            align="inherit"
                            key={"Monthly"}
                            sx={{
                              fontSize: "18px",
                              color: theme.palette.grey["400"],
                            }}
                          >
                            Monthly
                          </TableCell>
                          <TableCell
                            align="inherit"
                            key={"Balance"}
                            sx={{
                              fontSize: "18px",
                              color: theme.palette.grey["400"],
                            }}
                          >
                            Balance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tradelines?.map((row, index) => {
                          const isChecked =
                            checkedItems.find((item) => item.index === index)
                              ?.checked || false;
                          const disabled =
                            isLoading ||
                            (!isChecked &&
                              totalBalanceSelected + Number(row.balance) >
                                netFunding);

                          const isTenMonthsMaturity = checkTenMonthsMaturity({
                            isInstallment: row?.revolvingOrInstallment === "I",
                            isOpen: row?.accountStatus === "Open",
                            terms: row?.terms,
                            openDate: row?.openDate,
                          });

                          if (
                            //** Display only these ECOA tradelines  */
                            (row?.responsibility === "Individual" ||
                              row?.responsibility === "Joint Account") &&
                            row?.accountStatus === "Open" &&
                            row?.source === (isBorrower ? "B1" : "B2") &&
                            !row?.ignored &&
                            !row?.debtAddedByAdmin &&
                            Number(row?.balance ?? 0) > MIN_BALANCE_TRADELINE &&
                            !isTenMonthsMaturity
                          ) {
                            const monthlyPayment =
                              row.monthlyPayment === "UNKNOWN"
                                ? "UNKNOWN"
                                : dollarFormatter.format(
                                    Number(row.monthlyPayment ?? 0),
                                  );
                            const balanceAmount = dollarFormatter?.format(
                              Number(row?.balance ?? 0),
                            );
                            if (
                              !requestedAdmin ||
                              (requestedAdmin && Boolean(row?.proposedAdmin))
                            ) {
                              return (
                                <DebtItem
                                  key={index}
                                  balanceAmount={balanceAmount}
                                  monthlyPayment={monthlyPayment}
                                  disabled={disabled}
                                  row={row}
                                  index={index}
                                  hanleSelectTradeLine={hanleSelectTradeLine}
                                  theme={theme}
                                  isBorrower={isBorrower}
                                  loanId={loan?.id as string}
                                  handleRefreshData={handleRefreshData}
                                  currentIndex={index}
                                />
                              );
                            } else {
                              return null;
                            }
                          } else return null;
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "12px" }}
                >
                  <CButton
                    type="button"
                    variant="contained"
                    onClick={() => {
                      if (checkedDebts && checkedDebts?.length > 0) {
                        setOpenSlider(true);
                      } else {
                        handleConfirmDebts();
                      }
                    }}
                    sx={{
                      marginTop: 2,
                      whiteSpace: { sm: "wrap", md: "nowrap" },
                      fontSize: { sm: "12px", xs: "14px" },
                      padding: { sm: "10px", xs: "8px" },
                    }}
                    disabled={
                      maxdti === undefined ||
                      dti > maxdti ||
                      isLoading ||
                      step?.incomeVerification?.annualIncome === undefined ||
                      awaitB2Register ||
                      step?.incomeVerification?.needsReview ||
                      adminNeedToReviewIncomes
                    }
                    name="debtsTable-confirmDebts"
                    aria-label="debtsTable-confirmDebts"
                  >
                    {isLoading ? (
                      <CircularProgress size={32} sx={{ color: "white" }} />
                    ) : (
                      "Confirm and proceed to next step"
                    )}
                  </CButton>
                  <CButton
                    type="button"
                    variant="outlined"
                    onClick={showDebts}
                    sx={{ marginTop: 2, whiteSpace: "nowrap" }}
                  >
                    Cancel
                  </CButton>
                  <CButton
                    type="button"
                    disabled={
                      needsHelp ||
                      step?.incomeVerification?.status === "success"
                    }
                    variant="outlined"
                    onClick={() => setOpenHelpDialog(true)}
                    sx={{ marginTop: 2, whiteSpace: "nowrap" }}
                    name="debtsTable-needHelp"
                    aria-label="debtsTable-needHelp"
                  >
                    I need help
                  </CButton>
                </Box>
                {awaitB2Register && (
                  <Typography fontSize={15} color={"red"}>
                    To complete final approval, your coborrower must register.
                  </Typography>
                )}
              </>
            ) : null}
          </Box>
        )}
      </Box>

      <HelpDialog
        isOpen={openHelpDialog}
        onClose={() => setOpenHelpDialog(false)}
        isBorrower={isBorrower}
        loanId={loan?.id}
      />
      <CDialog
        error={errorDataMessage}
        open={errorDataMessage ? Boolean(errorDataMessage) : false}
        icon="warning"
        description={errorDataMessage}
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => {
            setErrorDataMessage(undefined);
          }}
          name="debtsTable-continue"
          aria-label="debtsTable-continue"
        >
          Continue
        </CButton>
      </CDialog>

      {openSlider &&
        currentId &&
        checkedDebts?.map((item, index) => {
          const currentIndex = tradelines?.findIndex((el) => el.id === item.id);
          return (
            <ChangeDebtsInformationModal
              key={index}
              counter={{
                current: currentId,
                total: checkedDebts?.length ?? 0,
              }}
              open={currentId - 1 === index}
              onClose={() => setOpenSlider(false)}
              tradeline={{
                accountNumber: item?.accountNumber,
                payto: item?.creditorName,
                paytoAddress: item?.address,
                id: item?.id,
                balance: item?.balance,
                monthlyPayment: item?.monthlyPayment,
              }}
              isBorrower={isBorrower}
              loanId={loan?.id as string}
              handleRefreshData={handleRefreshData}
              handleNextSlider={() => {
                handleNext(currentId + 1, checkedDebts?.length ?? 0);
              }}
              currentIndex={currentIndex}
            />
          );
        })}
    </>
  );
};

export default DebtsTable;
