import { Fragment, useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CLoader from "components/CLoader";
import EditInitialLoanAmount from "./EditInitialLoanAmount";
import EditModalWithSelect from "./EditModalWithSelect/EditModalWithSelect";
import useTypeOfCreditLine from "./useTypeOfCreditLine";
import useUser from "context/UserCustomer/useUserCustomerContext";
import ConfirmTXNotHomestead from "components/CTracker/StepsDialogs/ConfirmTXNotHomestead";
import { useOffers } from "screens/InitialOffer/useOffers";
import Loan from "typedef/Loan";
import dollarFormatter from "utils/FormatterDollar";
import toPercentage from "utils/toPercentage";
import OfferOptionsByFico from "./OfferOptionsByFico";
import AcceptOfferButton from "./AcceptOfferButton";
import WarningMessage from "./WarningMessage";
import CButton from "components/CButton";
import DrawRequestModal from "./DrawRequestModal";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useDefineLoanType from "./useDefineLoanType";
import API from "utils/API";
import FeesModal from "./FeesModal";
import { styled } from "@mui/system";
import useInitialHelocOffer from "./useInitialHelocOffer";
import useOccupancyList from "utils/useOccupancyList";
import useProceedsList from "utils/useOfProceedsList";
import PrimaryHomeAddress from "./PrimaryHomeAddress";
import {
  DemographicDataDialog,
  InnerPropsDemographicForm,
} from "components/CTracker/Steps/DemographicsVerification/DemographicDataDialog";
import useDemographicsData, {
  getDemographicsData,
} from "components/CTracker/Steps/DemographicsVerification/useDemographicData";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";
import { DemographicsVerification } from "typedef/BorrowerSteps";

const PERCENTAGE = 100;
const AMOUNT_OF_DECIMALS = 2;

const TypeOfCreditLine: React.FC = () => {
  const typeOfCreditLine = useTypeOfCreditLine();
  const theme = useTheme();
  const loanType = useDefineLoanType(typeOfCreditLine?.loan as Loan);
  const { user, logout } = useUser();
  const loan = typeOfCreditLine.loan;
  const offer = useInitialHelocOffer();
  const { offertOptionsByFico } = useOffers(typeOfCreditLine?.loan as Loan);
  const [isLoanAmountModalOpen, setIsLoanAmountModalOpen] = useState(false);
  const [isDrawRequestModalOpen, setIsDrawRequestModalOpen] = useState(false);
  const [isFeesModalOpen, setIsFeesModalOpen] = useState(false);
  const [isLoadingOffer, setIsLoadingOffer] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isEditOccupancyModalOpen, setIsEditOccupancyModalOpen] =
    useState(false);
  const [isEditUseOfProceedsModalOpen, setIsEditUseOfProceedsyModalOpen] =
    useState(false);
  const property = typeOfCreditLine?.loan?.property;
  const txNotAHomestead =
    typeOfCreditLine?.loan?.borrowerFlags?.txHomestead === false;
  const loanNotArchived = !typeOfCreditLine?.loan?.borrowerFlags?.archiveType;
  const [openModalConfirmTx, setOpenModalConfirmTx] = useState(txNotAHomestead);
  const [demographicsDialogOpen, setDemographicsDialogOpen] = useState(false);
  const formDemographics = useDemographicsData();

  const onSubmitDemographics = formDemographics.handleSubmit(async (data) => {
    const validatedDemographicsData =
      getDemographicsData<DemographicsDataChecks>({
        demographicsData: data,
        form: formDemographics,
      });
    if ("error" in validatedDemographicsData) {
      formDemographics.setErrorMessage(validatedDemographicsData.message);
      formDemographics.setDemographicData({ status: "pending" });
    } else {
      setDemographicsDialogOpen(false);
      formDemographics.setErrorMessage(undefined);
      const dataToSave = {
        ...validatedDemographicsData,
        status: "success",
      };
      formDemographics.setDemographicData(dataToSave);
    }
  });

  let bpoPending = false;

  if (
    typeOfCreditLine?.loan?.borrowerSteps?.appraisalVerification &&
    typeOfCreditLine?.loan?.borrowerFlags?.enableAppraisal
  ) {
    bpoPending = true;
  }
  useEffect(() => {
    setOpenModalConfirmTx(txNotAHomestead);
  }, [txNotAHomestead]);
  const handleSubmit = async (amount: number, loanId: string) => {
    try {
      setHasError(false);
      setIsLoadingOffer(true);
      const response = await API.post({
        url: "/change-offer-amount/initial",
        method: "PUT",
        data: {
          amount,
          loanId,
        },
      });
      if ("error" in response) {
        setHasError(true);
      } else {
        typeOfCreditLine.handleRefresh();
        offer.handleRrefresh();
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoadingOffer(false);
    }
  };
  const isFirstLien =
    typeOfCreditLine?.loan?.initialOffer.currentLoanBalance === 0 &&
    typeOfCreditLine?.loan.occupancy === "Primary Residence";
  //temporary TN validation
  const tnMaxOffer =
    typeOfCreditLine?.loan?.property?.address?.components.state_abbreviation ===
    "TN"
      ? Math.floor(typeOfCreditLine?.loan?.initialOffer?.tnMaxOffer ?? 0)
      : undefined;
  const parsedLoanAmount = dollarFormatter.format(
    typeOfCreditLine?.loan?.initialOffer?.amount ?? 0,
  );
  const parsedInterestRate = toPercentage(
    typeOfCreditLine?.loan?.initialOffer?.interestRate ?? 0,
  );
  const parsedMonthlyPayment = dollarFormatter.format(
    typeOfCreditLine?.loan?.initialOffer?.monthlyPayment ?? 0,
  );
  const drawPercentageNumber =
    ((typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0) *
      PERCENTAGE) /
    (typeOfCreditLine?.loan?.initialOffer?.amount ?? 0);
  const drawPercentage =
    drawPercentageNumber.toString().includes(".") &&
    drawPercentageNumber.toString().split(".")[1].length > AMOUNT_OF_DECIMALS
      ? drawPercentageNumber.toFixed(AMOUNT_OF_DECIMALS)
      : drawPercentageNumber;

  const netFundingWiredToBankCalculation =
    (typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0) -
    (typeOfCreditLine?.totalFees ?? 0);

  const parsedNetFundingWiredToBank = dollarFormatter.format(
    netFundingWiredToBankCalculation,
  );
  const estPointsAndFees =
    (typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0) -
    (netFundingWiredToBankCalculation ?? 0);
  const parsedEstPointsAndFees = dollarFormatter.format(estPointsAndFees);
  if (hasError) {
    return (
      <Container
        maxWidth="md"
        //eslint-disable-next-line
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            px={{ xs: 2, md: 0 }}
            fontSize={{ xs: "2rem", md: "2.5rem" }}
          >
            <span
              style={{ color: theme.palette.primary.main, marginRight: "10px" }}
            >
              {user?.firstName},{" "}
            </span>{" "}
            we are so sorry but we are no longer able to extend a HELOC offer at
            this time based on the data in your application. We apologize for
            any inconvenience.
          </Typography>
        </Box>
        <Box
          bgcolor={theme.palette.background.paper}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "36px",
            borderTopLeftRadius: "33px",
            borderTopRightRadius: "33px",
          }}
          py={"40px"}
          px={"70px"}
          mb="6px"
        >
          <Typography fontSize="25px">
            As things often change with time, we invite you to reapply in the
            future. Thank you for your time and consideration.
          </Typography>
          <Typography fontSize="25px">We hope you have a nice day.</Typography>
          <CButton
            fullWidth
            type="button"
            variant="contained"
            sx={{ borderRadius: "33px", paddingY: "19px", marginTop: 2 }}
            onClick={logout}
            name="confirmHELOCError"
          >
            Confirm
          </CButton>
        </Box>
      </Container>
    );
  }
  const occupancy = typeOfCreditLine?.loan?.occupancy;
  const showBorrowerAddress = occupancy !== "Primary Residence";

  const isDemographicsVerificationPending =
    formDemographics.demographicData?.status !== "success";

  const isLoading =
    !typeOfCreditLine ||
    !user ||
    !property ||
    isLoadingOffer ||
    (typeOfCreditLine.loading && !isLoanAmountModalOpen);

  const StyledGridItem = styled(Box)(({ theme }) => ({
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  }));

  return (
    <Fragment>
      <CLoader loading={isLoading} />
      <Container
        maxWidth="md"
        //eslint-disable-next-line
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <Typography
            mt={3}
            mb={4}
            variant="h1"
            letterSpacing={-0.8}
            px={{ xs: 2, md: 0 }}
            fontSize={{ xs: "1.2rem", md: "1.5rem" }}
            fontFamily={"Pro Display Regular"}
            sx={{
              "& .highlight": {
                color: theme.palette.primary.main,
                marginRight: "10px",
                lineHeight: { xs: 1.2, md: 1.5 },
                display: "block",
                fontSize: { xs: "1.7rem", md: "2.4rem" },
                fontFamily: "Pro Display Bold",
              },
              "& .secondary-text": {
                color: "black",
                display: "block",
              },
            }}
          >
            <span
              className="highlight"
              style={{ color: theme.palette.primary.main }}
            >
              Congrats {user?.firstName}! <br />
            </span>{" "}
            You've been pre-qualified for a HELOC for up to{" "}
            <span
              style={{
                color: theme.palette.primary.main,
                fontFamily: "Pro Display Bold",
              }}
            >
              $
              {tnMaxOffer //temporary TN validation
                ? tnMaxOffer.toLocaleString("en-US")
                : Math.floor(
                    typeOfCreditLine?.loan?.initialOffer?.maxOffer ?? 0,
                  ).toLocaleString("en-US")}
            </span>
            !{!isFirstLien && " View your options below"}.
          </Typography>
        </Box>
        <WarningMessage loan={typeOfCreditLine?.loan} />
        <Box
          bgcolor={theme.palette.background.paper}
          color={theme.palette.text.primary}
          boxShadow="rgba(0,0,0,0.16) 0px 1px 4px"
          sx={{ borderRadius: "24px" }}
          px={{ xs: 1, md: 2 }}
          py={{ xs: 1, md: 2 }}
          width={{ xs: "90vw", md: "auto" }}
          margin={{ xs: "0 auto", md: "0" }}
          display="grid"
        >
          <StyledGridItem>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                maxWidth="220px"
                width="100%"
              >
                Property:
              </Typography>
              <Typography
                fontFamily={"Pro Display Bold"}
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
              >
                {typeOfCreditLine?.loan?.property?.fullAddress}
              </Typography>
            </Box>
          </StyledGridItem>
          <StyledGridItem>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                maxWidth="220px"
                width="100%"
              >
                Loan Request:
              </Typography>
              <Box
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", md: "center" },
                }}
                display="flex"
                alignItems="center"
                gap={{ xs: 0, md: 1 }}
              >
                <Typography
                  sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                  fontFamily="Pro Display Bold"
                >
                  {parsedLoanAmount}
                </Typography>
                {!bpoPending && (
                  <Typography
                    onClick={() => setIsLoanAmountModalOpen(true)}
                    sx={{
                      cursor: "pointer",
                      fontSize: { xs: "1rem", md: "1.2rem" },
                    }}
                    color="#6767AA"
                  >
                    Edit
                  </Typography>
                )}
              </Box>
            </Box>
          </StyledGridItem>
          <StyledGridItem>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                maxWidth="220px"
                width="100%"
              >
                Interest Rate:
              </Typography>
              <Typography
                fontFamily={"Pro Display Bold"}
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  alignItems: { xs: "flex-start", md: "center" },
                  fontSize: { xs: "1rem", md: "1.3rem" },
                }}
                gap={{ xs: 0, md: 1 }}
              >
                {parsedInterestRate}{" "}
                <Typography
                  sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                  color={theme.palette.text.disabled}
                >
                  ({parsedMonthlyPayment} est. monthly payment)
                </Typography>
              </Typography>
            </Box>
          </StyledGridItem>
          <StyledGridItem>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                maxWidth="220px"
                width="100%"
              >
                Draw
              </Typography>
              <Box
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", md: "center" },
                }}
                display="flex"
                alignItems="center"
                fontFamily={"Pro Display Bold"}
                gap={{ xs: 0, md: 1 }}
              >
                {Number(drawPercentage) === PERCENTAGE ? (
                  <Typography
                    fontFamily={"Pro Display Bold"}
                    sx={{
                      cursor: "pointer",
                      fontSize: { xs: "1rem", md: "1.2rem" },
                    }}
                  >
                    {dollarFormatter.format(
                      typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ??
                        0,
                    )}{" "}
                    {`(${drawPercentage}% draw)`}
                  </Typography>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="12px"
                  >
                    <WarningIcon style={{ color: "#EAB308" }} />
                    <Typography
                      fontFamily={"Pro Display Bold"}
                      sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                      color="#EAB308"
                    >
                      {dollarFormatter.format(
                        typeOfCreditLine?.loan?.initialOffer
                          ?.initialDrawAmount ?? 0,
                      )}{" "}
                      {`(${drawPercentage}% draw)`}
                    </Typography>
                  </Box>
                )}
                <Typography
                  onClick={() => setIsDrawRequestModalOpen(true)}
                  sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}
                  color="#6767AA"
                >
                  Edit
                </Typography>
              </Box>
            </Box>
          </StyledGridItem>
          <StyledGridItem>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                maxWidth="220px"
                width="100%"
              >
                Est. Points and Fees
              </Typography>
              <Box
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", md: "center" },
                }}
                display="flex"
                alignItems="center"
                fontFamily={"Pro Display Bold"}
                gap={{ xs: 0, md: 1 }}
              >
                <Typography
                  sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                  fontFamily={"Pro Display Bold"}
                >
                  {parsedEstPointsAndFees}
                </Typography>
                <Typography
                  onClick={() => setIsFeesModalOpen(true)}
                  sx={{
                    cursor: "pointer",
                    fontSize: { xs: "1rem", md: "1.2rem" },
                  }}
                  color="#6767AA"
                >
                  Show details
                </Typography>
              </Box>
            </Box>
          </StyledGridItem>
          <StyledGridItem>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                maxWidth="220px"
                width="100%"
              >
                Est. Net Proceeds
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                fontFamily={"Pro Display Bold"}
              >
                {parsedNetFundingWiredToBank}
              </Typography>
            </Box>
          </StyledGridItem>
          <StyledGridItem>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                maxWidth="220px"
                width="100%"
              >
                Occupancy
              </Typography>
              <Box
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", md: "center" },
                }}
                display="flex"
                alignItems="center"
                gap={{ xs: 0, md: 1 }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "1rem", md: "1.3rem" },
                    fontFamily: "Pro Display Bold",
                  }}
                >
                  {typeOfCreditLine?.loan?.occupancy}
                </Typography>
                <Typography
                  sx={{ p: "0px" }}
                  onClick={() => setIsEditOccupancyModalOpen(true)}
                >
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: { xs: "1rem", md: "1.2rem" },
                    }}
                    color="#6767AA"
                  >
                    Edit
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </StyledGridItem>
          <StyledGridItem>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                maxWidth="220px"
                width="100%"
              >
                Use of proceeds
              </Typography>
              <Box
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", md: "center" },
                }}
                display="flex"
                alignItems="center"
                gap={{ xs: 0, md: 1 }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "1rem", md: "1.3rem" },
                    fontFamily: "Pro Display Bold",
                  }}
                >
                  {typeOfCreditLine?.loan?.initialOffer?.useProceeds}
                </Typography>
                <Typography
                  sx={{ p: "0px" }}
                  onClick={() => setIsEditUseOfProceedsyModalOpen(true)}
                >
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: { xs: "1rem", md: "1.2rem" },
                    }}
                    color="#6767AA"
                  >
                    Edit
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </StyledGridItem>
          {showBorrowerAddress && (
            <Box mx={6} pt={2} px={1}>
              <PrimaryHomeAddress useDefineLoanType={loanType} />
            </Box>
          )}
        </Box>
        <Box
          bgcolor={theme.palette.background.paper}
          width={{ xs: "87vw", md: "auto" }}
          margin={{ xs: "1rem auto", md: "1rem 0" }}
          sx={{ borderRadius: "24px" }}
          mt={1}
          py={{ xs: "24px", md: "55px" }}
          px={{ xs: "16px", md: "55px" }}
          boxShadow="rgba(0,0,0,0.16) 0px 1px 4px"
        >
          <Typography lineHeight={1.2} fontSize={{ xs: "20px", md: "22px" }}>
            Here are some additional loan amount and rate options
          </Typography>
          <OfferOptionsByFico
            offertOptions={offertOptionsByFico}
            currentLoan={typeOfCreditLine.loan}
            loanId={loan?.id}
            handleSubmit={handleSubmit}
            isDisabled={
              !!(
                typeOfCreditLine?.loan?.borrowerSteps?.appraisalVerification &&
                typeOfCreditLine?.loan?.borrowerFlags?.enableAppraisal
              )
            }
          />

          <Box mt={8}>
            {isDemographicsVerificationPending ? (
              <Typography
                fontSize={20}
                variant="subtitle1"
                color={theme.palette.text.primary}
              >
                Before submitting your application, please complete your
                demographic information:
              </Typography>
            ) : null}
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <CButton
                fullWidth
                type="button"
                variant="contained"
                sx={{ marginTop: "10px" }}
                loading={typeOfCreditLine?.loading}
                onClick={() => setDemographicsDialogOpen(true)}
                disabled={!isDemographicsVerificationPending}
                name="demographic"
                cy-data="demographic"
              >
                Demographics
                {isDemographicsVerificationPending ? "" : " completed"}
              </CButton>
              {formDemographics.demographicData?.status === "success" ? (
                <CheckCircleOutlineIcon
                  color="success"
                  style={{ width: 45, height: 45, paddingTop: "6px" }}
                />
              ) : null}
            </Box>
          </Box>
          <Typography
            fontSize={{ xs: "16px", md: "20px" }}
            mt="40px"
            sx={{ textWrap: "pretty" }}
          >
            Once you Accept Offer, we will confirm your debt-to-income ratio to
            ensure it's under 50% by verifying your income electronically either
            by accessing your payroll system or you can connect to your online
            bank account.
          </Typography>
          <AcceptOfferButton
            functionHandler={() =>
              loanType.saveOffer(
                "HELOC",
                typeOfCreditLine.loan?.initialOffer?.initialDrawAmount ?? 0,
                formDemographics.demographicData as DemographicsVerification,
              )
            }
            isDemographicsSuccess={
              formDemographics.demographicData?.status === "success"
            }
            primaryHomeAddress={loanType.primaryHomeAddress}
            isAccepting={loanType.isAccepting}
            isLoading={loanType.loading}
            primaryHomeAddressError={loanType.primaryHomeAddressError}
            occupancy={loanType.loan?.occupancy}
            loan={typeOfCreditLine?.loan}
          />
          {isLoanAmountModalOpen ? (
            <EditInitialLoanAmount
              endpoint="/change-offer-amount/initial"
              handleRefreshLoan={typeOfCreditLine.handleRefresh}
              handleRefreshOffer={offer.handleRrefresh}
              handleSubmit={handleSubmit}
              open={isLoanAmountModalOpen}
              isFirstLien={isFirstLien}
              tnMaxOffer={
                tnMaxOffer ??
                typeOfCreditLine?.loan?.initialOffer?.maxOffer ??
                0
              } //temporary TN validation
              loanId={loan?.id}
              onSubmit={() => {
                typeOfCreditLine.handleRefresh();
              }}
              onClose={() => {
                setIsLoanAmountModalOpen(false);
              }}
              state={property?.address?.components?.state_abbreviation}
              setEditLoanAmountOpen={setIsLoanAmountModalOpen}
            />
          ) : null}
          {isDrawRequestModalOpen ? (
            <DrawRequestModal
              isOpen={isDrawRequestModalOpen}
              handleCloseModal={() => setIsDrawRequestModalOpen(false)}
              handleChangeDrawAmount={typeOfCreditLine.handleChangeDrawAmount}
              {...offer}
            />
          ) : null}
          {isEditOccupancyModalOpen ? (
            <EditModalWithSelect
              title={"Enter in your desired occupancy"}
              subtitle={"Occupancy"}
              selectOptions={useOccupancyList}
              open={isEditOccupancyModalOpen}
              loanAmount={
                typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0
              }
              onClose={() => setIsEditOccupancyModalOpen(false)}
              endpoint="/save-to-loan/save-occupancy"
              loanId={loan?.id}
              loanValueSelectedByDefault={typeOfCreditLine?.loan?.occupancy}
              loading={isLoadingOffer}
              setIsLoadingOffer={setIsLoadingOffer}
              setHasError={setHasError}
              setIsEditModal={setIsEditOccupancyModalOpen}
              handleRefreshLoan={typeOfCreditLine.handleRefresh}
              handleRefreshOffer={offer.handleRrefresh}
            />
          ) : null}
          {isEditUseOfProceedsModalOpen ? (
            <EditModalWithSelect
              title={"Enter in your desired use of proceeds"}
              subtitle={"Use of proceeds"}
              selectOptions={useProceedsList}
              open={isEditUseOfProceedsModalOpen}
              loanAmount={
                typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0
              }
              onClose={() => setIsEditUseOfProceedsyModalOpen(false)}
              endpoint="/save-to-loan/save-use-of-proceeds"
              loanId={loan?.id}
              loanValueSelectedByDefault={
                typeOfCreditLine?.loan?.initialOffer?.useProceeds
              }
              loading={isLoadingOffer}
              setIsLoadingOffer={setIsLoadingOffer}
              setHasError={setHasError}
              setIsEditModal={setIsEditUseOfProceedsyModalOpen}
              handleRefreshLoan={typeOfCreditLine.handleRefresh}
              handleRefreshOffer={offer.handleRrefresh}
            />
          ) : null}
          {isFeesModalOpen ? (
            <FeesModal
              openModal={isFeesModalOpen}
              onClose={() => setIsFeesModalOpen(false)}
              loan={typeOfCreditLine?.loan}
              loanAmount={
                typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0
              }
              totalFees={typeOfCreditLine.totalFees}
              originationFee={typeOfCreditLine.originationFee}
            />
          ) : null}
        </Box>
      </Container>
      {openModalConfirmTx && loanNotArchived && (
        <ConfirmTXNotHomestead
          open={openModalConfirmTx}
          onAccept={() => {
            setOpenModalConfirmTx(false);
          }}
        />
      )}
      <DemographicDataDialog
        open={demographicsDialogOpen}
        onClose={() => setDemographicsDialogOpen(false)}
        form={formDemographics as InnerPropsDemographicForm}
        onSubmit={onSubmitDemographics}
      />
    </Fragment>
  );
};
export default TypeOfCreditLine;
