import { useEffect, useState } from "react";
import useLoan from "components/CTracker/useLoan";
import PricingEngine from "typedef/PricingEngine";
import API from "./API";
import { handleOcuppancy } from "screens/BorrowerRegisterForm/useRegisterForm";

export const useGetDtiByCreditScore = () => {
  const loan = useLoan();
  const [dtiItems, setDtiItems] = useState<{
    dti: number | undefined;
    dtiHurdle: number;
    dtiAdjustor: number;
  }>({
    dti: undefined,
    dtiHurdle: 0,
    dtiAdjustor: 0,
  });
  const [pricingEngineFetched, setPricingEngineFetched] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const creditScore =
    loan?.borrowerSteps?.hardpullVerification?.hardCreditScore ??
    loan?.borrowerSteps?.creditVerification.creditScore ??
    loan?.initialOffer.creditScore;

  const TIME_OUT_NUMBER = 20000;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!loan) {
        setError(
          "Loan information could not be retrieved within the timeout period.",
        );
      }
    }, TIME_OUT_NUMBER);

    const fetchData = async () => {
      if (!loan || pricingEngineFetched) return;
      const privateLabelId = loan.privateLabelId;
      const helocType = handleOcuppancy(loan.occupancy ?? "Primary Residence");
      const pricingEngineId = `${privateLabelId}#${helocType}`;
      const encodePricingEngineId = encodeURIComponent(pricingEngineId);

      if (!privateLabelId || !creditScore) return;

      try {
        const result = await API.get<PricingEngine>(
          `/get-heloc-pricing-engine?id=${encodePricingEngineId}`,
        );

        if ("error" in result) {
          throw new Error("Error fetching pricing engine data");
        }

        const pricingEngine = result.data;
        const rangesCreditScore: PricingEngine["rangesCreditScore"] =
          pricingEngine?.rangesCreditScore;

        for (const range of rangesCreditScore) {
          if (creditScore >= range.min && creditScore <= range.max) {
            const dti = range.dti;
            const dtiHurdle = range.dtiHurdle;
            const dtiAdjustor = range.dtiAdjustor;
            setDtiItems({ dti, dtiHurdle, dtiAdjustor });
            setPricingEngineFetched(true);
            setError(null);
            return;
          }
        }

        throw new Error("No matching DTI items found");
      } catch (error) {
        throw new Error("DTI items could not be retrieved");
      }
    };

    fetchData();
    return () => clearTimeout(timeout);
  }, [loan, pricingEngineFetched, creditScore]);

  useEffect(() => {
    setPricingEngineFetched(false);
  }, [creditScore]);

  if (error) {
    throw new Error(error);
  }

  return dtiItems;
};
