import { Fragment, useCallback, useEffect, useState } from "react";
import CButton from "components/CButton";
import useRegisterForm from "./useRegisterForm";
import CTermsDialog from "components/CTermsDialog";
import CAddressAutocomplete from "components/CAddressAutocomplete";
import {
  Box,
  Link,
  List,
  Button,
  Checkbox,
  MenuItem,
  ListItem,
  useTheme,
  Container,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import CDialog from "components/CDialog";
import CLoader from "components/CLoader";
import parseMoney from "utils/parseMoney";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import CTextField from "components/CTextField";
import HTMLtoolTip from "components/HTMLtoolTip";
import CNumberField from "components/CNumberField";
import ConfirmValuesDialog from "./ConfirmValuesDialog";
import { useInvite } from "context/Invite/useInviteContext";
import FortunaInformation from "components/FortunaInformation";
import useUser from "context/UserCustomer/useUserCustomerContext";
import CCommunicationsDialog from "components/CCommunicationsDialog";
import LoanOfficerInformation from "./LoanOfficerInformation";
import CMultipleMarksOfferInviteLink from "./CMultipleMarkOffer";
import CPasswordField from "components/CPasswordField";
import { passwordMsgHelper } from "CONST";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";
import API from "utils/API";
import { Occupancy, OccupancyItem } from "typedef/PrivateLabel";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import moment from "moment";
import LaduInformation from "components/LaduInformation";
import blueSageValidateLicense, {
  useDebounce,
} from "./blueSageValidateLicense";
import BorrowerRegisterForm from "screens/BorrowerRegisterForm";
import FormCoborrower from "./FormCoborrower";
import dollarFormatter from "utils/FormatterDollar";

export type MasterLoanOfficer = {
  sk: string;
  NMLS: number;
  firstName: string;
  lastName: string;
  licenseNumber: string;
};

interface DividerProps {
  color: string;
}

const Divider: React.FC<DividerProps> = ({ color }) => (
  <div
    style={{
      borderBottom: `1px solid ${color}`,
      margin: "30px 0",
    }}
  />
);

const Form: React.FC = () => {
  const user = useUser();
  const theme = useTheme();
  const invite = useInvite();
  const form = useRegisterForm();
  const setValues = form.setValue;
  const { privateLabel } = usePrivateLabel();
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageManualAddress, setErrorMessageManualAddress] =
    useState(false);
  const [loadingBSApi, setLoadingBSApi] = useState(false);
  const [loadingBSApiManualAddress, setLoadingBSApiManualAddress] =
    useState(false);
  const [addCoBorrower, setAddCoBorrower] = useState(false);
  const [showIncomeInputsBorrower, setShowIncomeInputsBorrower] = useState({
    salaryIncome: false,
    selfEmploymentIncome: false,
    socialIncome: false,
    otherIncome: false,
  });
  const [showIncomeInputsCoborrower, setShowIncomeInputsCoborrower] = useState({
    salaryIncomeCoborrower: false,
    selfEmploymentIncomeCoborrower: false,
    socialIncomeCoborrower: false,
    otherIncomeCoborrower: false,
  });
  const incomeFieldsBorrower = [
    "salaryIncome",
    "selfEmploymentIncome",
    "socialIncome",
    "otherIncome",
  ] as const;
  const incomeFieldsCoborrower = [
    "salaryIncomeCoborrower",
    "selfEmploymentIncomeCoborrower",
    "socialIncomeCoborrower",
    "otherIncomeCoborrower",
  ] as const;
  const [borrowerIncomes, setBorrowerIcomes] = useState({
    salaryIncome: 0,
    selfEmploymentIncome: 0,
    socialIncome: 0,
    otherIncome: 0,
  });
  const [coborrowerIncomes, setCoborrowerIncomes] = useState({
    salaryIncomeCoborrower: 0,
    selfEmploymentIncomeCoborrower: 0,
    socialIncomeCoborrower: 0,
    otherIncomeCoborrower: 0,
  });
  const params = useParams();
  const isABroker = params["brokerName"];
  const sourceId = params["sourceId"];
  const { setPLMasterLoanOfficer } = form;
  const control = form.control;
  const occupancy = form.watch("occupancyType");
  const showBorrowerAddress = occupancy && occupancy !== "Primary Residence";
  const asTxOcuppancyType = form.getValues().propertyAddress?.state === "TX";
  const propertyStreet = form.watch("propertyAddress.street_line");
  const borrowerStreet = form.watch("borrowerAddress.street_line");
  const occupancyTypes = asTxOcuppancyType
    ? [
        {
          value: "Primary Residence" as Occupancy,
          label: "Primary Residence" as Occupancy,
        } as OccupancyItem,
      ]
    : privateLabel?.occupancyTypesEnabled?.map(
        (value) =>
          ({
            value: value,
            label: value,
          }) as OccupancyItem,
      );

  const disableSubmit =
    !(form.watch("hasAcceptCertify") && form.watch("hasAcceptTerms")) ||
    (form.isInvalidStateId as boolean) ||
    errorMessage ||
    errorMessageManualAddress;

  const { errors } = form.formState;

  const disabledField = invite && invite?.inviteType === "one_use";
  const disabledFieldMarital =
    disabledField && invite?.maritalStatus ? true : false;
  const [fees, setFees] = useState<PrivateLabelFees>();

  useEffect(() => {
    API.get<PrivateLabelFees>(
      `/getServiceUnsecured/origination-fees?state=${form.currentState}`,
    )
      .then((response) => {
        if ("error" in response) {
          console.error(response);
        } else {
          setFees(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching fees:", error);
      });
  }, [form.currentState]);

  const propertyAddressValue = form.watch("propertyAddress");
  const propertyAddressManualAddress = form.watch("manualAddress.state");
  const debounceManualAddress = useDebounce(propertyAddressManualAddress, 1000);
  const loanOfficerId = invite?.loanOfficerId;

  const getBluesageValidationLicense = useCallback(async () => {
    if (propertyAddressValue?.state || debounceManualAddress) {
      const valueToValidate =
        propertyAddressValue?.state || debounceManualAddress;
      if (loanOfficerId) {
        valueToValidate === propertyAddressValue?.state
          ? setLoadingBSApi(true)
          : setLoadingBSApiManualAddress(true);
        const result = await blueSageValidateLicense(
          valueToValidate.toUpperCase(),
          loanOfficerId,
          privateLabel,
        );

        const isValidMasterLoanOfficerData = (data: MasterLoanOfficer) => {
          return ["sk", "NMLS", "firstName", "lastName", "licenseNumber"].every(
            (key) => key in data,
          );
        };

        if (!privateLabel?.isNFTYDoor && result.data.data) {
          const { id, ...rest } = result.data.data[0];
          if (isValidMasterLoanOfficerData(rest)) {
            setPLMasterLoanOfficer(rest as MasterLoanOfficer);
          }
        }

        if (
          result?.data?.message !== "Valid license" &&
          propertyAddressValue?.state
        ) {
          setErrorMessage(true);
          setErrorMessageManualAddress(false);
          setLoadingBSApi(false);
        } else if (
          result?.data?.message !== "Valid license" &&
          debounceManualAddress
        ) {
          setErrorMessageManualAddress(true);
          setErrorMessage(false);
          setLoadingBSApiManualAddress(false);
        } else {
          setErrorMessageManualAddress(false);
          setErrorMessage(false);
          setLoadingBSApi(false);
          setLoadingBSApiManualAddress(false);
        }
      }
    }
  }, [
    propertyAddressValue?.state,
    debounceManualAddress,
    loanOfficerId,
    setPLMasterLoanOfficer,
    privateLabel,
  ]);

  const handleCheckboxChange = (
    checkbox: keyof typeof showIncomeInputsBorrower,
  ) => {
    setShowIncomeInputsBorrower((prev) => {
      const newState = { ...prev, [checkbox]: !prev[checkbox] };
      const borrowerField = `${checkbox}` as keyof typeof borrowerIncomes;
      if (!newState[checkbox]) {
        setValues(borrowerField, undefined);
        form.clearErrors(borrowerField);
        handleInputChange(
          { target: { value: "0" } } as React.ChangeEvent<HTMLInputElement>,
          borrowerField,
        );
      } else {
        setValues(borrowerField, "");
      }
      return newState;
    });
  };
  const handleCheckboxChangeCoborrower = (
    checkbox: keyof typeof showIncomeInputsCoborrower,
  ) => {
    setShowIncomeInputsCoborrower((prev) => {
      const newState = { ...prev, [checkbox]: !prev[checkbox] };
      const coborrowerField = `${checkbox}` as keyof typeof coborrowerIncomes;
      if (!newState[checkbox]) {
        setValues(coborrowerField, undefined);
        form.clearErrors(coborrowerField);
        handleInputChangeCoborrower(
          { target: { value: "0" } } as React.ChangeEvent<HTMLInputElement>,
          coborrowerField,
        );
      } else {
        setValues(coborrowerField, "");
      }
      return newState;
    });
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: keyof typeof borrowerIncomes,
  ) => {
    const { value } = e.target;
    const numericValue = value.replace(/[$,\s]/g, "");
    const numberValue = parseFloat(numericValue);
    setBorrowerIcomes((prev) => ({
      ...prev,
      [name]: isNaN(numberValue) ? 0 : numberValue,
    }));
  };
  const handleInputChangeCoborrower = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: keyof typeof coborrowerIncomes,
  ) => {
    const { value } = e.target;
    const numericValue = value.replace(/[$,\s]/g, "");
    const numberValue = parseFloat(numericValue);
    setCoborrowerIncomes((prev) => ({
      ...prev,
      [name]: isNaN(numberValue) ? 0 : numberValue,
    }));
  };
  const deleteCoborrower = () => {
    setAddCoBorrower(false);
    setValues("addCoborrower", "No");
    setValues("legalFirstNameCoborrower", undefined);
    setValues("legalLastNameCoborrower", undefined);
    setValues("legalMiddleNameCoborrower", undefined);
    setValues("legalSuffixCoborrower", undefined);
    setValues("maritalStatusCoborrower", undefined);
    setValues("phoneCoborrower", undefined);
    setValues("confirmEmailAddressCoborrower", undefined);
    setValues("salaryIncomeCoborrower", undefined);
    setValues("selfEmploymentIncomeCoborrower", undefined);
    setValues("socialIncomeCoborrower", undefined);
    setValues("otherIncomeCoborrower", undefined);
    setValues("startDateCoborrower", undefined);
    setValues("employerNameCoborrower", undefined);
    setValues("employmentTypeCoborrower", undefined);
    setValues("totalAnnualIncomeCoborrower", undefined);
    setCoborrowerIncomes({
      salaryIncomeCoborrower: 0,
      selfEmploymentIncomeCoborrower: 0,
      socialIncomeCoborrower: 0,
      otherIncomeCoborrower: 0,
    });
    form.clearErrors("incomeGroupCoborrower");
    form.clearErrors("totalAnnualIncomeCoborrower");
  };
  const totalAnnualIncome = Object.values(borrowerIncomes).reduce(
    (a, b) => a + b,
    0,
  );
  const totalAnnualIncomeCoborrower = Object.values(coborrowerIncomes).reduce(
    (a, b) => a + b,
    0,
  );
  const checkboxLabels = [
    "Salary or hourly wages",
    "Self Employment",
    "Social security, pension, disability",
    "Other",
  ];

  useEffect(() => {
    getBluesageValidationLicense();
  }, [getBluesageValidationLicense]);

  const creditScoreOptions = form.creditScoreOptions;

  const isXS = useMediaQuery(theme.breakpoints.only("xs"));

  if (privateLabel?.isNFTYDoor) {
    return (
      <Fragment>
        {isABroker && <CLoader loading={invite ? false : true} />}
        <Container maxWidth="md" disableGutters={isXS}>
          <Box>
            <Typography
              mt={4}
              mb={5}
              variant="h1"
              lineHeight={1}
              color={theme.palette.text.primary}
              letterSpacing={-0.8}
              px={{ xs: 2, md: 0 }}
              fontSize={{ xs: "3.5rem", md: "5rem" }}
            >
              <span style={{ color: theme.palette.primary.main }}>Hello. </span>{" "}
              Get pre-qualified
              <br />
              for your loan here.
            </Typography>
          </Box>

          {invite?.loanOfficer?.firstName && (
            <LoanOfficerInformation phone={invite?.loanOfficer?.phone} />
          )}
          {sourceId === "ladu" && <LaduInformation />}
          {sourceId === "fortuna" && <FortunaInformation />}
          <Box
            borderRadius={10}
            bgcolor={theme.palette.background.paper}
            sx={{ scrollbarWidth: "none" }}
          >
            <Box px={{ xs: 2, md: 6 }} py={{ xs: 4, md: 5 }}>
              <form onSubmit={form.onSubmit}>
                <Controller
                  name={"propertyAddress"}
                  control={control}
                  render={({ field }) => (
                    <>
                      {loadingBSApi && (
                        <CircularProgress
                          style={{ width: 16, height: 16, color: "grey" }}
                        />
                      )}
                      <CAddressAutocomplete
                        name="propertyAddressAutocomplete"
                        placeholder="Start typing your home address and please select from the dropdown."
                        address={field.value}
                        setAddress={field.onChange}
                        sx={{ mb: 3, mt: { xs: 2, md: 0 } }}
                        label="Subject Property Address"
                        disabled={disabledField}
                        error={
                          errorMessage
                            ? "Your loan officer is not licensed in this state, please contact them for next steps"
                            : errors?.propertyAddress?.message
                        }
                        isInvalidStateId={form.isInvalidStateId}
                      />
                    </>
                  )}
                />
                {propertyStreet === "My address is not listed" && (
                  <>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      alignItems={"flex-start"}
                      flexDirection={{ xs: "column", md: "row" }}
                      gap={1}
                    >
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manualAddress.street_number"
                          render={({ field }) => (
                            <CTextField
                              label="Street Number"
                              placeholder="Street number"
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={
                                errors?.manualAddress?.street_number?.message
                              }
                            />
                          )}
                        />
                      </Box>
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manualAddress.street_line"
                          render={({ field }) => (
                            <CTextField
                              label="Street Name"
                              placeholder="Street name"
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={
                                errors?.manualAddress?.street_line?.message
                              }
                            />
                          )}
                        />
                      </Box>
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manualAddress.street_suffix"
                          render={({ field }) => (
                            <CTextField
                              label="Street Suffix"
                              placeholder="Ln, Rd, St..."
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={
                                errors?.manualAddress?.street_suffix?.message
                              }
                            />
                          )}
                        />
                      </Box>
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manualAddress.secondary"
                          render={({ field }) => (
                            <CTextField
                              label="Apt/Suite"
                              placeholder="Unit #"
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={errors?.manualAddress?.secondary?.message}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      alignItems={"flex-start"}
                      flexDirection={{ xs: "column", md: "row" }}
                      gap={1}
                    >
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manualAddress.city"
                          render={({ field }) => (
                            <CTextField
                              label="City"
                              placeholder="City"
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={errors?.manualAddress?.city?.message}
                            />
                          )}
                        />
                      </Box>
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manualAddress.state"
                          render={({ field }) => (
                            <>
                              <CTextField
                                label="State"
                                placeholder="VA, FL, TX..."
                                fullWidth
                                autoComplete="off"
                                disabled={disabledField}
                                sx={{ mb: 3 }}
                                {...field}
                                error={
                                  errorMessageManualAddress
                                    ? "Your loan officer is not licensed in this state, please contact them for next steps"
                                    : errors?.manualAddress?.state?.message
                                }
                              />
                              {loadingBSApiManualAddress && (
                                <CircularProgress
                                  style={{
                                    width: 16,
                                    height: 16,
                                    color: "grey",
                                  }}
                                />
                              )}
                            </>
                          )}
                        />
                        {propertyStreet === "My address is not listed" &&
                          form?.isInvalidStateId &&
                          !errors?.manualAddress?.state?.message && (
                            <Typography
                              mt={-2}
                              mb={2}
                              ml={1.5}
                              variant="body1"
                              lineHeight={1}
                              color={theme.palette.error.main}
                              fontSize={{ xs: "0.75rem" }}
                            >
                              {form?.isInvalidStateId}
                            </Typography>
                          )}
                      </Box>
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manualAddress.zipcode"
                          render={({ field }) => (
                            <CTextField
                              label="Zipcode"
                              placeholder="Zipcode"
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={errors?.manualAddress?.zipcode?.message}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  </>
                )}
                <Controller
                  name={"occupancyType"}
                  control={control}
                  render={({ field }) => (
                    <CTextField
                      select
                      disabled={disabledField}
                      fullWidth
                      tooltip={
                        <HTMLtoolTip
                          title={
                            <Fragment>
                              <Typography color="inherit">
                                Owner Occupied
                              </Typography>
                              {
                                "I/We will occupy the subject property as my/our principal residence within 60 days after the date of closing as required by, and in compliance with, the terms of the Deed of Trust/Mortgage/Security Instrument relating to the subject property. I/We will continue to occupy the property as my/our principal residence for at least one year after the date of occupancy, unless Lender otherwise agrees in writing or extenuating circumstances exist which are beyond my/our control."
                              }
                              <br />
                              <br />
                              <Typography color="inherit">
                                Occupied as a Second Home
                              </Typography>
                              {
                                "I/We will occupy the subject property as my/our second residence as required by, and in compliance with, the terms of the Deed of Trust/Mortgage/Security Instrument relating to the subject property."
                              }
                              <br />
                              <br />
                              <Typography color="inherit">
                                Investment Property - Will Not Occupy
                              </Typography>
                              {"I/We will not occupy the subject property."}
                            </Fragment>
                          }
                        >
                          <Button />
                        </HTMLtoolTip>
                      }
                      label="Occupancy Type"
                      placeholder="Select"
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.occupancyType?.message}
                    >
                      {occupancyTypes?.map((item, index) => {
                        return (
                          <MenuItem value={item?.value} key={index}>
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                    </CTextField>
                  )}
                />
                {showBorrowerAddress && (
                  <>
                    <Controller
                      name="borrowerAddress"
                      control={control}
                      render={({ field }) => (
                        <CAddressAutocomplete
                          {...field}
                          onChange={undefined}
                          placeholder="Please enter the address of the home you currently live in."
                          address={field.value}
                          setAddress={field.onChange}
                          sx={{ mb: 3, mt: { xs: 2, md: 0 } }}
                          label="Primary home address"
                          //@ts-ignore
                          error={errors?.borrowerAddress?.message}
                        />
                      )}
                    />
                    {borrowerStreet === "My address is not listed" && (
                      <>
                        <Box
                          width={"100%"}
                          display={"flex"}
                          alignItems={"flex-start"}
                          flexDirection={{ xs: "column", md: "row" }}
                        >
                          <Box
                            width={{ xs: "100%", md: "50%" }}
                            mr={{ xs: 0, md: 1 }}
                          >
                            <Controller
                              control={control}
                              name="manualBorrowerAddress.street_number"
                              render={({ field }) => (
                                <CTextField
                                  label="Street Number"
                                  placeholder="Street number"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manualBorrowerAddress?.street_number
                                      ?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                          <Box
                            width={{ xs: "100%", md: "50%" }}
                            mr={{ xs: 0, md: 1 }}
                          >
                            <Controller
                              control={control}
                              name="manualBorrowerAddress.street_line"
                              render={({ field }) => (
                                <CTextField
                                  label="Street Name"
                                  placeholder="Street name"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manualBorrowerAddress?.street_line
                                      ?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                          <Box
                            width={{ xs: "100%", md: "50%" }}
                            mr={{ xs: 0, md: 1 }}
                          >
                            <Controller
                              control={control}
                              name="manualBorrowerAddress.street_suffix"
                              render={({ field }) => (
                                <CTextField
                                  label="Street Suffix"
                                  placeholder="Ln, Rd, St..."
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manualBorrowerAddress?.street_suffix
                                      ?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                          <Box width={{ xs: "100%", md: "50%" }}>
                            <Controller
                              control={control}
                              name="manualBorrowerAddress.secondary"
                              render={({ field }) => (
                                <CTextField
                                  label="Apt/Suite"
                                  placeholder="Unit #"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manualBorrowerAddress?.secondary
                                      ?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                        </Box>
                        <Box
                          width={"100%"}
                          display={"flex"}
                          alignItems={"flex-start"}
                          flexDirection={{ xs: "column", md: "row" }}
                          gap={1}
                        >
                          <Box width={{ xs: "100%", md: "50%" }}>
                            <Controller
                              control={control}
                              name="manualBorrowerAddress.city"
                              render={({ field }) => (
                                <CTextField
                                  label="City"
                                  placeholder="City"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manualBorrowerAddress?.city?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                          <Box width={{ xs: "100%", md: "50%" }}>
                            <Controller
                              control={control}
                              name="manualBorrowerAddress.state"
                              render={({ field }) => (
                                <CTextField
                                  label="State"
                                  placeholder="VA, FL, TX..."
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manualBorrowerAddress?.state
                                      ?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                          <Box width={{ xs: "100%", md: "50%" }}>
                            <Controller
                              control={control}
                              name="manualBorrowerAddress.zipcode"
                              render={({ field }) => (
                                <CTextField
                                  label="Zipcode"
                                  placeholder="Zipcode"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manualBorrowerAddress?.zipcode
                                      ?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                  </>
                )}
                <Controller
                  control={control}
                  name="estimatedHomeValue"
                  render={({ field }) => (
                    <CNumberField
                      fullWidth
                      label="Home Value"
                      placeholder="$200,000"
                      isNumericString
                      thousandSeparator
                      disabled={disabledField}
                      allowNegative={false}
                      allowEmptyFormatting={false}
                      prefix="$"
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.estimatedHomeValue?.message}
                      value={parseMoney(field.value)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="currentLoanBalance"
                  render={({ field }) => (
                    <CNumberField
                      fullWidth
                      label="Current Loan Balance"
                      placeholder="$100,000"
                      isNumericString
                      thousandSeparator
                      disabled={disabledField}
                      allowNegative={false}
                      allowEmptyFormatting={false}
                      prefix="$"
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.currentLoanBalance?.message}
                      value={parseMoney(field.value)}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="requestedLoanAmount"
                  render={({ field }) => (
                    <CNumberField
                      fullWidth
                      label="Requested Loan Amount"
                      placeholder="$65,000"
                      isNumericString
                      thousandSeparator
                      disabled={disabledField}
                      allowNegative={false}
                      allowEmptyFormatting={false}
                      prefix="$"
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.requestedLoanAmount?.message}
                      value={parseMoney(field.value)}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="useOfProceeds"
                  render={({ field }) => (
                    <CTextField
                      select
                      fullWidth
                      label="Use of Proceeds"
                      disabled={disabledField}
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.useOfProceeds?.message}
                    >
                      <MenuItem value="Home Improvement">
                        Home Improvement
                      </MenuItem>
                      <MenuItem value="Debt Consolidation">
                        Debt Consolidation
                      </MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </CTextField>
                  )}
                />
                <Controller
                  control={control}
                  name={"creditScoreRange"}
                  render={({ field }) => (
                    <CTextField
                      select
                      fullWidth
                      label="Credit Score"
                      disabled={disabledField}
                      sx={{ mb: 3 }}
                      {...field}
                      onChange={(event) => field.onChange(event.target.value)}
                      value={field.value || ""}
                    >
                      {creditScoreOptions?.map((option, index) => (
                        <MenuItem
                          key={`${index}${option?.value}`}
                          value={option?.value}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </CTextField>
                  )}
                />

                {form.watch("estimatedHomeValue") &&
                  form.watch("currentLoanBalance") &&
                  form.watch("requestedLoanAmount") && (
                    <div>
                      <Typography
                        mt={4}
                        mb={5}
                        variant="h1"
                        lineHeight={1.2}
                        color={theme.palette.text.primary}
                        letterSpacing={-0.8}
                        px={{ xs: 2, md: 0 }}
                        fontSize={{ xs: "1.5rem", md: "2rem" }}
                      >
                        PreQual Offer Summary
                      </Typography>
                      {
                        <CMultipleMarksOfferInviteLink
                          offers={form.offertOptionsByFico}
                          requestedLoanAmount={form.requestedLoanAmount}
                          interestRate={form.interestRate}
                          originationPoints={fees}
                        />
                      }
                    </div>
                  )}

                <Box display={"flex"}>
                  <Typography
                    mt={4}
                    mb={5}
                    variant="h1"
                    lineHeight={1.2}
                    color={theme.palette.text.primary}
                    letterSpacing={-0.8}
                    px={{ xs: 2, md: 0 }}
                    fontSize={{ xs: "1.5rem", md: "2rem" }}
                  >
                    Primary Borrower
                  </Typography>
                  {!addCoBorrower && (
                    <Button
                      variant="text"
                      sx={{
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setAddCoBorrower(!addCoBorrower);
                        setShowIncomeInputsCoborrower({
                          salaryIncomeCoborrower: false,
                          selfEmploymentIncomeCoborrower: false,
                          socialIncomeCoborrower: false,
                          otherIncomeCoborrower: false,
                        });
                        setValues("addCoborrower", "Yes");
                      }}
                    >
                      + Add Co-Borrower
                    </Button>
                  )}
                </Box>

                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                    <Controller
                      control={control}
                      name="legalFirstNameBorrower"
                      render={({ field }) => (
                        <CTextField
                          label="Legal First Name"
                          placeholder="John"
                          fullWidth
                          autoComplete="off"
                          disabled={disabledField}
                          sx={{ mb: 3 }}
                          {...field}
                          error={errors?.legalFirstNameBorrower?.message}
                        />
                      )}
                    />
                  </Box>

                  <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
                    <Controller
                      control={control}
                      name="legalLastNameBorrower"
                      render={({ field }) => (
                        <CTextField
                          label="Legal Last Name"
                          placeholder="Doe"
                          fullWidth
                          autoComplete="off"
                          disabled={disabledField}
                          sx={{ mb: 3 }}
                          {...field}
                          error={errors?.legalLastNameBorrower?.message}
                        />
                      )}
                    />
                  </Box>
                </Box>

                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                    <Controller
                      control={control}
                      name="legalMiddleNameBorrower"
                      render={({ field }) => (
                        <CTextField
                          label="Legal Middle Name (Optional)"
                          fullWidth
                          placeholder="Paul"
                          autoComplete="off"
                          sx={{ mb: 3 }}
                          {...field}
                          error={errors?.legalMiddleNameBorrower?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                    <Controller
                      control={control}
                      name="legalSuffixBorrower"
                      render={({ field }) => (
                        <CTextField
                          label="Suffix (Optional)"
                          placeholder="JR, SR"
                          fullWidth
                          autoComplete="off"
                          disabled={disabledField}
                          sx={{ mb: 3 }}
                          {...field}
                          error={errors?.legalSuffixBorrower?.message}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Controller
                  control={control}
                  name="maritalStatusBorrower"
                  render={({ field }) => (
                    <CTextField
                      select
                      fullWidth
                      label="Marital Status"
                      disabled={disabledFieldMarital}
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.maritalStatusBorrower?.message}
                    >
                      <MenuItem value="Unmarried">Unmarried</MenuItem>
                      <MenuItem value="Married">Married</MenuItem>
                      <MenuItem value="Separated">Separated</MenuItem>
                    </CTextField>
                  )}
                />
                <Controller
                  control={control}
                  name="phoneBorrower"
                  render={({ field }) => (
                    <CNumberField
                      fullWidth
                      autoComplete="off"
                      label="Phone Number"
                      disabled={disabledField}
                      placeholder="+1(123)-123-1234"
                      format={
                        process.env.REACT_APP_ENV === "prod"
                          ? "+1###############"
                          : "+###############"
                      }
                      sx={{ mb: 3 }}
                      allowEmptyFormatting
                      {...field}
                      error={errors?.phoneBorrower?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="confirmEmailAddressBorrower"
                  render={({ field }) => (
                    <CTextField
                      label="Email Address"
                      disabled={disabledField}
                      placeholder="Type in here"
                      fullWidth
                      autoComplete="off"
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.confirmEmailAddressBorrower?.message}
                    />
                  )}
                />
                <Divider color={theme.palette.text.disabled} />
                <Box mb={2}>
                  <Typography
                    variant="h2"
                    lineHeight={1.2}
                    color={theme.palette.text.primary}
                    letterSpacing={-0.8}
                    px={{ xs: 2, md: 0 }}
                    fontSize={{ xs: "1.5rem", md: "2rem" }}
                  >
                    Annual Income
                  </Typography>
                  <Typography
                    variant="caption"
                    color={theme.palette.text.secondary}
                    px={{ xs: 2, md: 0 }}
                  >
                    Please provide more details
                  </Typography>
                </Box>
                {incomeFieldsBorrower.map((incomeField, index) => (
                  <Box key={incomeField}>
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        sx={{ pl: 0 }}
                        checked={showIncomeInputsBorrower[incomeField]}
                        onChange={() => handleCheckboxChange(incomeField)}
                      />
                      <Typography>{checkboxLabels[index]}</Typography>
                    </Box>
                    {showIncomeInputsBorrower[incomeField] && (
                      <Box pl={5}>
                        <Controller
                          control={control}
                          name={incomeField}
                          render={({ field }) => (
                            <CNumberField
                              name={incomeField}
                              fullWidth
                              label={`$ Total Annual Compensation - ${checkboxLabels[index]}`}
                              placeholder="$200,000"
                              isNumericString
                              thousandSeparator
                              disabled={disabledField}
                              allowNegative={false}
                              allowEmptyFormatting={false}
                              prefix="$"
                              sx={{ mb: 1 }}
                              value={parseMoney(field.value ?? "")}
                              error={errors[incomeField]?.message}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                field.onChange(
                                  e.target.value
                                    .replace(/[$,\s]/g, "")
                                    .replace(/^0+(?=\d)/, ""),
                                );
                                handleInputChange(e, incomeField);
                              }}
                            />
                          )}
                        />
                        <Typography
                          color={theme.palette.text.secondary}
                          variant="caption"
                        >
                          This will be verified via online payroll systems.
                        </Typography>
                        {index + 1 === 1 && (
                          <>
                            <Controller
                              control={control}
                              name="employerNameBorrower"
                              render={({ field }) => (
                                <CTextField
                                  label="Employer Name"
                                  placeholder={privateLabel?.lenderName}
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3, mt: 2 }}
                                  {...field}
                                  error={errors?.employerNameBorrower?.message}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="startDateBorrower"
                              render={({ field }) => (
                                <CTextField
                                  type="date"
                                  label="Start Date"
                                  InputProps={{
                                    inputProps: {
                                      min: "1908-02-05",
                                      max: moment()
                                        .subtract(0, "year")
                                        .format("YYYY-MM-DD"),
                                    },
                                  }}
                                  placeholder="John"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={errors?.startDateBorrower?.message}
                                />
                              )}
                            />
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
                {errors.inputsGroup && (
                  <Typography
                    color={theme.palette.error.main}
                    fontWeight={400}
                    fontSize={"0.75rem"}
                  >
                    {errors.inputsGroup.message}
                  </Typography>
                )}
                <Box mt={3}>
                  <Typography variant="h6" color={theme.palette.primary.main}>
                    Total Annual Income:
                    {dollarFormatter.format(totalAnnualIncome ?? 0)}
                  </Typography>
                </Box>
                {errors.totalAnnualIncomeBorrower && (
                  <Typography
                    color={theme.palette.error.main}
                    fontWeight={400}
                    fontSize={"0.75rem"}
                  >
                    {errors.totalAnnualIncomeBorrower.message}
                  </Typography>
                )}
                <Divider color={theme.palette.text.disabled} />
                {addCoBorrower && (
                  <>
                    <FormCoborrower
                      control={control}
                      disabledField={disabledField ?? true}
                      disabledFieldMarital={disabledFieldMarital}
                      errors={errors}
                      deleteCoborrower={deleteCoborrower}
                    />
                    <Box mb={2}>
                      <Typography
                        variant="h2"
                        lineHeight={1.2}
                        color={theme.palette.text.primary}
                        letterSpacing={-0.8}
                        px={{ xs: 2, md: 0 }}
                        fontSize={{ xs: "1.5rem", md: "2rem" }}
                      >
                        Annual Income
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        px={{ xs: 2, md: 0 }}
                      >
                        Please provide more details
                      </Typography>
                    </Box>
                    {incomeFieldsCoborrower.map((incomeField, index) => (
                      <Box key={incomeField}>
                        <Box display="flex" alignItems="center">
                          <Checkbox
                            sx={{ pl: 0 }}
                            checked={showIncomeInputsCoborrower[incomeField]}
                            onChange={() =>
                              handleCheckboxChangeCoborrower(incomeField)
                            }
                          />
                          <Typography>{checkboxLabels[index]}</Typography>
                        </Box>
                        {showIncomeInputsCoborrower[incomeField] && (
                          <Box pl={5}>
                            <Controller
                              control={control}
                              name={incomeField}
                              render={({ field }) => (
                                <CNumberField
                                  fullWidth
                                  label={`$ Total Annual Compensation - ${checkboxLabels[index]}`}
                                  placeholder="$200,000"
                                  isNumericString
                                  thousandSeparator
                                  disabled={disabledField}
                                  allowNegative={false}
                                  allowEmptyFormatting={false}
                                  prefix="$"
                                  sx={{ mb: 1 }}
                                  value={parseMoney(field.value ?? "")}
                                  error={errors[incomeField]?.message}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    field.onChange(
                                      e.target.value
                                        .replace(/[$,\s]/g, "")
                                        .replace(/^0+(?=\d)/, ""),
                                    );
                                    handleInputChangeCoborrower(e, incomeField);
                                  }}
                                />
                              )}
                            />
                            <Typography color="textSecondary" variant="caption">
                              This will be verified via online payroll systems.
                            </Typography>
                            {index + 1 === 1 && (
                              <>
                                <Controller
                                  control={control}
                                  name="employerNameCoborrower"
                                  render={({ field }) => (
                                    <CTextField
                                      label="Employer Name"
                                      placeholder={privateLabel?.lenderName}
                                      fullWidth
                                      autoComplete="off"
                                      disabled={disabledField}
                                      sx={{ mb: 3, mt: 2 }}
                                      {...field}
                                      error={
                                        errors?.employerNameCoborrower?.message
                                      }
                                    />
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="startDateCoborrower"
                                  render={({ field }) => (
                                    <CTextField
                                      type="date"
                                      label="Start Date"
                                      InputProps={{
                                        inputProps: {
                                          min: "1908-02-05",
                                          max: moment()
                                            .subtract(0, "year")
                                            .format("YYYY-MM-DD"),
                                        },
                                      }}
                                      placeholder="John"
                                      fullWidth
                                      autoComplete="off"
                                      disabled={disabledField}
                                      sx={{ mb: 3 }}
                                      {...field}
                                      error={
                                        errors?.startDateCoborrower?.message
                                      }
                                    />
                                  )}
                                />
                              </>
                            )}
                          </Box>
                        )}
                      </Box>
                    ))}
                    {errors.incomeGroupCoborrower && (
                      <Typography
                        color={theme.palette.error.main}
                        fontWeight={400}
                        fontSize={"0.75rem"}
                      >
                        {errors.incomeGroupCoborrower.message}
                      </Typography>
                    )}
                    <Box mt={3}>
                      <Typography
                        variant="h6"
                        color={theme.palette.primary.main}
                      >
                        Total Secondary Borrower Annual Income:
                        {dollarFormatter.format(
                          totalAnnualIncomeCoborrower ?? 0,
                        )}
                      </Typography>
                    </Box>
                    {errors.totalAnnualIncomeCoborrower && (
                      <Typography
                        color={theme.palette.error.main}
                        fontWeight={400}
                        fontSize={"0.75rem"}
                      >
                        {errors.totalAnnualIncomeCoborrower.message}
                      </Typography>
                    )}
                    <Divider color={theme.palette.text.disabled} />
                    <Box mt={3}>
                      <Typography
                        variant="h6"
                        color="black"
                        fontStyle={"initial"}
                      >
                        Total Household Income:
                        {dollarFormatter.format(
                          totalAnnualIncome + totalAnnualIncomeCoborrower ?? "",
                        )}
                      </Typography>
                    </Box>
                    <Divider color={theme.palette.text.disabled} />
                  </>
                )}
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <CPasswordField
                      data-cy="password"
                      label="Create Password"
                      placeholder="Type in here"
                      fullWidth
                      autoComplete="new-password"
                      helperText={passwordMsgHelper}
                      {...field}
                      error={errors?.password?.message}
                    />
                  )}
                />
                <List
                  disablePadding
                  sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}
                >
                  <ListItem
                    disableGutters
                    alignItems="flex-start"
                    sx={{ mt: 1 }}
                  >
                    <Box display="inline" fontFamily="Pro Display Semibold">
                      <Controller
                        control={control}
                        name="hasAcceptTerms"
                        render={({ field }) => (
                          <Checkbox
                            sx={{ pl: 0 }}
                            onClick={() => field.onChange(!field.value)}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <Typography
                      mt={0.8}
                      variant="subtitle1"
                      color={theme.palette.text.primary}
                      textAlign={"justify"}
                    >
                      I agree to the{" "}
                      {privateLabel?.isNFTYDoor ? (
                        <Button
                          variant="text"
                          color="primary"
                          sx={{
                            cursor: "pointer",
                            textDecoration: "none",
                            padding: 0,
                            minWidth: "auto",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                              textDecoration: "none",
                            },
                          }}
                          onClick={() => {
                            form.setOpenedDialog("terms");
                          }}
                        >
                          Terms of Service
                        </Button>
                      ) : (
                        <Link
                          color={theme.palette.primary.main}
                          textAlign={"justify"}
                          sx={{ cursor: "pointer", textDecoration: "none" }}
                          href={privateLabel?.footerPolicy?.privacyTerms}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </Link>
                      )}{" "}
                      and{" "}
                      <Link
                        color={theme.palette.primary.main}
                        textAlign={"justify"}
                        sx={{ cursor: "pointer", textDecoration: "none" }}
                        href={privateLabel?.footerPolicy.privacyPolicyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                  </ListItem>
                  <ListItem disableGutters alignItems="flex-start">
                    <Box display="inline" fontFamily="Pro Display Semibold">
                      <Controller
                        control={control}
                        name={"hasAcceptCertify"}
                        render={({ field }) => (
                          <Checkbox
                            sx={{ pl: 0 }}
                            onClick={() => field.onChange(!field.value)}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <Typography
                      mt={0.8}
                      variant="subtitle1"
                      color={theme.palette.text.primary}
                      textAlign={"justify"}
                    >
                      I certify that by checking this box I have read{" "}
                      {privateLabel?.isNFTYDoor ? (
                        <Button
                          variant="text"
                          color="primary"
                          sx={{
                            cursor: "pointer",
                            textDecoration: "none",
                            padding: 0,
                            minWidth: "auto",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                              textDecoration: "none",
                            },
                          }}
                          onClick={() => {
                            form.setOpenedDialog("communications");
                          }}
                        >
                          "NFTYDoor's" Electronic Communications Policy
                        </Button>
                      ) : (
                        <Link
                          color={theme.palette.primary.main}
                          textAlign={"justify"}
                          sx={{ cursor: "pointer", textDecoration: "none" }}
                          href={privateLabel?.footerPolicy?.privacyPolicyLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {privateLabel?.lenderName} Electronic Communications
                          Policy
                        </Link>
                      )}
                      , and consent to receive all legally required notices and
                      disclosures and other communications (“Communications”)
                      from
                      {privateLabel
                        ? privateLabel.lenderName
                        : "NFTYDoor LLC"}{" "}
                      electronically and not on paper. I also confirm I am able
                      to access, view, retain and print all of the
                      Communications{" "}
                      {privateLabel ? privateLabel.lenderName : "NFTYDoor LLC"}{" "}
                      provides me in electronic form.
                    </Typography>
                  </ListItem>
                </List>
                <Typography
                  my={"1rem"}
                  color={theme.palette.text.secondary}
                  variant="subtitle2"
                  textAlign={"justify"}
                >
                  By pressing the “I agree” button immediately following this
                  notice, I am providing ‘written instructions’ to{" "}
                  {privateLabel ? privateLabel.lenderName : "NFTYDoor"} under
                  the Fair Credit Reporting Act authorizing{" "}
                  {privateLabel ? privateLabel.lenderName : "NFTYDoor"} to
                  obtain information from my personal credit profile or other
                  information from Experian. I authorize{" "}
                  {privateLabel ? privateLabel.lenderName : "NFTYDoor"} to
                  obtain such information solely to conduct a pre-qualification
                  for credit and acknowledge this will not affect my credit
                  score.
                </Typography>
                <CButton
                  data-cy="submitInvite"
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={disableSubmit}
                  sx={{ mt: 4, mb: 2, fontSize: 18, textTransform: "inherit" }}
                  loading={
                    form.formState.isSubmitting || user.isGettingLoggedIn
                  }
                >
                  I Agree
                </CButton>
              </form>
            </Box>
          </Box>
        </Container>

        <CDialog
          icon="cancel"
          description={form.errorMessage}
          open={form.openedDialog === "error"}
        >
          <CButton
            fullWidth
            variant="contained"
            onClick={() => {
              form.setOpenedDialog(undefined);
            }}
          >
            Go back
          </CButton>
        </CDialog>

        <CDialog
          icon="warning"
          description={form.emailAlreadyUseOnPL}
          open={form.openedDialog === "EMAIL_IN_USE_ON_PL"}
        >
          <CButton
            fullWidth
            variant="contained"
            onClick={() => {
              form.setShowSuccessDialog(true);
              form.setOpenedDialog(undefined);
            }}
          >
            Continue
          </CButton>
        </CDialog>
        <ConfirmValuesDialog form={form} />
        <CTermsDialog
          open={form.openedDialog === "terms"}
          handleClose={() => form.setOpenedDialog(undefined)}
        />
        <CCommunicationsDialog
          open={form.openedDialog === "communications"}
          handleClose={() => form.setOpenedDialog(undefined)}
        />
      </Fragment>
    );
  } else {
    return <BorrowerRegisterForm />;
  }
};

export default Form;
