import usePlaid from "./usePlaid";
import Typography from "@mui/material/Typography";
import CTrackerTimelineItem from "components/CTimelineItem";
import { Fragment } from "react";
import useTracker from "components/CTracker/useTrackerContext";
import TruvBridge from "@truv/react";
import useTruvIncome from "./useTruvIncome";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import UploadEachDocument from "components/CTracker/StepsDialogs/UploadEachDocument";
import API from "utils/API";
import { useIncomeVerification } from "./useIncomeVerification";
import { DebtToIncomeModal } from "./DebtToIncomeModal";
import useDebts from "./DebtsTable/useDebts";
import SelfGuidedDTI from "./SelfGuidedDTI/SelfGuidedDTI";
import { Box } from "@mui/material";
import color from "context/MaterialUITheme/color";

export const messageCardStyles = {
  backgroundColor: color.background,
  color: color.black,
  padding: 1,
  paddingX: 2,
  marginTop: 2,
  borderRadius: 2,
};

const IncomeVerification = ({
  trackerStatusColor,
}: {
  trackerStatusColor: TrackerStatusColor;
}) => {
  const plaid = usePlaid();
  const truv = useTruvIncome();

  const { loan, type, isModalOpen } = useTracker();
  const {
    processingStatus,
    loading,
    status,
    prevStep,
    isBorrower,
    isSelfEmployed,
    taxReturnsRequested,
    setLoading,
    step,
    setOpenTruv,
    promptManualTruv,
    isB1Docs,
    isB2Docs,
    openTruv,
    docsToUpload,
    loanBalanceCredit,
    incomeUpdate,
    message,
    showDebtToIncomeModal,
    needDocsUpload,
    requestedAdminMessage,
  } = useIncomeVerification(loan, type, isModalOpen);

  const { defaultDTI } = useDebts({ loan, step, isBorrower });

  const handleOpenTruv = async (manualUpload?: boolean) => {
    setLoading(true);
    await truv.getBridgeToken(manualUpload);
    setOpenTruv(true);
    setLoading(false);
  };

  const handleOpenPlaid = async () => {
    setLoading(true);
    plaid.loadEmployment();
    setLoading(false);
  };

  const handleUploadTaxReturns = async () => {
    setLoading(true);
    await API.post({
      url: "/save-to-loan/request-tax-return",
      data: {
        loanId: loan?.id,
        isBorrower,
      },
    });
    setLoading(false);
  };

  return (
    <CTrackerTimelineItem
      processingStatus={processingStatus ?? loading}
      status={status}
      title="Income"
      loading={loading}
      tooltip={`For W2 applicants, we use a payroll verification system.  For self-employed applicants, we connect with your bank account to verify income.`}
      {...(promptManualTruv &&
        status === "analyzing" &&
        prevStep &&
        !(isBorrower ? isB1Docs : isB2Docs) &&
        !isSelfEmployed && {
          action: {
            message: "Upload your docs here!",
            onClick: async () => {
              await handleOpenTruv();
            },
          },
        })}
    >
      <TruvBridge
        isOpened={openTruv}
        onClose={() => setOpenTruv(false)}
        bridgeToken={truv.bridgeToken as string}
        onSuccess={async (public_token: string) => {
          setOpenTruv(false);
          await truv.sendPublicToken(public_token);
        }}
      />

      <Typography variant="h6" color={[trackerStatusColor[status]]}>
        {status === "analyzing" &&
          !isBorrower &&
          step?.incomeVerification.message}

        {status === "failed" && message}
        {status === "success" && !isBorrower && message}
        {isBorrower &&
          status === "success" &&
          loan?.coborrowerInvite &&
          loan.coborrowerSteps?.incomeVerification.status !== "success" && (
            <Fragment>
              <br />
              Waiting on the borrower to complete their steps
            </Fragment>
          )}

        {isBorrower && (status === "analyzing" || status === "success") && (
          <Fragment>
            {status === "analyzing"
              ? `You reported $${(incomeUpdate
                  ? Number(incomeUpdate)
                  : 0
                ).toLocaleString("en-US")} of annual household income.`
              : `We verified $${(incomeUpdate
                  ? Number(incomeUpdate)
                  : 0
                ).toLocaleString("en-US")} of annual income.`}
            <br />
            We detected ${loanBalanceCredit} of annual debts from your credit
            report.
            <br />
            {status === "analyzing" && (
              <Box sx={messageCardStyles}>
                {message.replace(requestedAdminMessage, "")}
              </Box>
            )}
            {status === "analyzing" &&
              message.includes(requestedAdminMessage) && (
                <p
                  style={{
                    color: "#FA9D4F",
                    fontWeight: 500,
                    margin: 0,
                  }}
                >
                  {requestedAdminMessage}
                </p>
              )}
            {status === "success" && message}
          </Fragment>
        )}
        {status === "analyzing" && needDocsUpload && !showDebtToIncomeModal && (
          <UploadEachDocument docsToUpload={docsToUpload} loan={loan} />
        )}
      </Typography>
      {status !== "pending" && step?.incomeVerification?.annualIncome ? (
        <SelfGuidedDTI isBorrower={isBorrower} loan={loan} step={step} />
      ) : null}
      <DebtToIncomeModal
        open={
          (status !== "pending" &&
            defaultDTI === Infinity &&
            step?.incomeVerification?.message !==
              "We received your response and our CX team is now reviewing it." &&
            step?.incomeVerification?.needsReview === false) ||
          showDebtToIncomeModal
        }
        docsToUpload={docsToUpload}
        handlePlaid={handleOpenPlaid}
        handleTruv={isSelfEmployed ? handleUploadTaxReturns : handleOpenTruv}
        handleOpenTruv={handleOpenTruv}
        needDocsUpload={needDocsUpload}
        isSelfEmployed={isSelfEmployed}
        loading={loading || plaid.loadingPlaid}
        loan={loan}
        taxReturnsRequested={taxReturnsRequested}
        step={step}
      />
    </CTrackerTimelineItem>
  );
};

export default IncomeVerification;
